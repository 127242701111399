import {createStore} from 'redux';

const rowUpdate = (state = [], action) => {
    switch (action.type) {
        case 'SET_PLATFORM_LIST':
            state = action.payload;
            return state;
        default:
            break;
    }
    return state;
};

const store = createStore(rowUpdate);

export default store;
