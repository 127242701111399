import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const vietnameseMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            revenue: 'Revenue',
            views: 'Streams',
            total_payment: 'Remain payment',
            total_revenue: 'Lifetime revenue',
            trending_tracks: 'Trending Tracks',
            contract_revenue_list: 'Contract Revenue List',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce dashboard',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this dashboard',
            },
        },
        menu: {
            landingPage: 'Landing Page',
            posts: 'Posts',
            projects: 'Projects',
            configuration: 'Configuration',
            profile: 'Profile',
            payments: 'Payments',
            contracts: 'Albums & Singles',
            revenues: 'Revenues',

        },
        profile: {
            title: 'Profile',
            menu_name: 'Profile',
            username: 'Username',
            company_name: 'Company Name',
            address: 'Address',
            email: 'Email',
            password: 'Password',
            new_password: 'New password',
            repeat_password: 'Repeat Password',
            submit: 'Submit',
            enable_email_notification: 'Email Notification',
            bank: {
                title: 'Bank Account',
                bank_name: 'Bank Name',
                bank_number: 'Bank Number',
                bank_receiver: 'Bank Receiver',
                bank_brand: 'Bank Brand',
            },
        },
        validation: {
            password_miss_match: 'Repeat password not correct',
            email_invalid: 'Email format invalid'
        },
        auth: {
            email: 'Email',
            submit_reset_email: 'Send To Email',
            new_password: 'New password',
            repeat_password: 'Confirm password',
            change_password: 'Update',
            reset_key: 'Reset code',
            submit_reset_key: 'Submit',
            enter_reset_key_title: 'Enter reset code from email',
            enter_reset_email_title: 'Enter email to reset password',
            new_password_title: 'Enter your new password'
        }
    },
    resources: {
        projects: {
            name: 'Project |||| Projects',
            filters: {
                active: {
                    name: 'Active',
                    on: 'On',
                    off: 'Off',
                },
            },
            fields: {
                projects: 'Projects',
                name: 'Name',
            },
        },
        posts: {
            name: 'Post |||| Posts',
            list: {
                search: 'Search',
            },
            form: {
                summary: 'Summary',
                body: 'Body',
                miscellaneous: 'Miscellaneous',
                comments: 'Comments',
            },
            edit: {
                title: 'Post "%{title}"',
            },
            action: {
                save_and_edit: 'Save and Edit',
                save_and_add: 'Save and Add',
                save_and_show: 'Save and Show',
                save_with_average_note: 'Save with Note',
            },
        },
        revenues: {
            title: 'Revenues'
        },
        contracts: {
            title: 'Albums & Singles'
        }
    },
    error: {
        http: {
            401: "Not logged in"
        }
    }
};

export default vietnameseMessages;
