import React, {useCallback} from "react";
import {datePickerRanges} from "../dashboard/DatePickerRanges";
import moment from "moment";
import { useInput } from "react-admin";
import * as dateFns from "date-fns";
import {MonthRangePicker} from "./MonthRangePicker";


export const DateRangePickerInput = (props) => {
    const {
        format,
        label,
        fullWidth,
        helperText,
        onBlur,
        onChange,
        onFocus,
        options,
        disabled,
        parse,
        resource,
        source,
        validate,
        record,
        ...rest
    } = props;
    const {
        id,
        input: {onChange: finalFormOnChange, type, value, ...inputProps},
        isRequired,
        meta: {error, submitError, touched},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'checkbox',
        validate,
        ...rest,
    });

    const defaultDateRange = {
        startDate: moment(new Date()).add(-1, 'y').toDate(),
        endDate: new Date(),
    };
    const [dateRange, setDateRange] = React.useState<any>(defaultDateRange);


    const handleChange = useCallback(
        (value) => {
            if(!!value) {
                value = {
                    start: dateFns.format(value.startDate, "yyyy-MM-dd"),
                    end: dateFns.format(value.endDate, "yyyy-MM-dd")
                };
            }

            finalFormOnChange(value);
        },
        [finalFormOnChange]
    );

    return  <MonthRangePicker
        style={{padding: '10px 0'}}

        value={[dateRange.startDate, dateRange.endDate]}
        onChange={(range) => {
            setDateRange({startDate: range[0], endDate: range[1]})
            handleChange(dateRange);
        }}
        format={"MM/YYYY"}
        ranges={datePickerRanges}
    />

}
