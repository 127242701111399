import * as React from 'react';
import { Route } from 'react-router-dom';
import { CustomRoute } from 'react-admin';
import {Profile} from "./profile/Profile";
import EnterEmail from "./layout/EnterEmail";
import EnterNewPassword from "./layout/EnterNewPassword";
import EnterResetKey from "./layout/EnterResetKey";
import ChangePassword from "./profile/ChangePassword";

export default [
    <Route exact path="/profile" render={() => <Profile />} />,
    <Route exact path="/change-password" render={() => <ChangePassword />} />,
    <Route<CustomRoute> exact path="/forgot-password" component={EnterEmail} noLayout/>,
    <Route<CustomRoute> exact path="/enter-reset-key" component={EnterResetKey} noLayout/>,
    <Route<CustomRoute> exact path="/update-password" component={EnterNewPassword} noLayout/>,
];
