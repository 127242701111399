import * as React from 'react';
import { FC } from 'react';
import {MonetizationOn} from '@material-ui/icons';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';
interface Props {
    value?: number;
}

const TotalRevenue: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            // to="/commands"
            icon={MonetizationOn}
            title={translate('pos.dashboard.revenue')}
            subtitle={value.toLocaleString([], {maximumFractionDigits: 0})}
        />
    );
};

export default TotalRevenue;
