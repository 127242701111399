import * as React from 'react';
import { FC } from 'react';
import {Headset} from '@material-ui/icons';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const Views: FC<Props> = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            // to="/commands"
            icon={Headset}
            title={translate('pos.dashboard.views')}
            subtitle={value.toLocaleString()}
        />
    );
};

export default Views;
