import { fetchUtils } from 'ra-core';
import restDataProvider from "./restDataProvider";

export const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = JSON.parse(localStorage.getItem('jwt') as string);
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

export const simpleDataProvider = restDataProvider(
    process.env.REACT_APP_ADMIN_API_ENDPOINT,
    httpClient
);

