import {useEffect} from "react";
import {simpleRest} from "./simpleRest";
import platformDataStore from "./store/platformDataStore";
import {useDataProvider, useGetList, useLogout, useAuthProvider} from "react-admin";

export const InitialResource = () => {
    const logout = useLogout();
    const dataProviderProxy = useDataProvider();
    let authProvider = useAuthProvider();
    useEffect(()=>{
        simpleRest.getList("platforms/list", {
            pagination: {perPage: 100, page: 0},
            filter: {},
            sort: {field: 'displayOrder', order: 'ASC'}
        }).then((rs) => {
            if(rs) {
                platformDataStore.dispatch({type: 'SET_PLATFORM_LIST', payload: rs.json})
            } else {
                console.log("some thing went wrong")
            }
        }).catch((rs)=> {
            if(rs.status == '401' || rs.status == '403') {
                // logout();
            }
            console.log(rs)
        })

    }, []);

    return <div></div>

}
