import React, {FunctionComponent, useEffect, useState} from "react";
import DatePicker from "react-datepicker";

import "./react-datepicker.css";
import {Button, Divider, Popover} from "@material-ui/core";
import {Clear, DateRange} from "@material-ui/icons";
import {Text} from "recharts";
import moment from 'moment';

export type ValueType = [Date?, Date?];

export interface RangeType {
    label: React.ReactNode;
    closeOverlay?: boolean;
    value: ValueType | ((value?: ValueType) => ValueType);
}

interface MonthRangePickerProps {
    ranges: RangeType[];
    onChange: (date: Date[]) => void;
    format: string;
    style?: any;
    value?: Date[];
}

export const MonthRangePicker = (props: MonthRangePickerProps) => {
    const {ranges, onChange, format, style,value} = props;
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    /*onst onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };*/
    useEffect(() => {
        setStartDate(value&& value[0]? value[0] : null);
        setEndDate(value&& value[1]? value[1] : null);
    },[value])

    const open = Boolean(anchorEl);

    const dateFormat = format ? format : 'MM-YYYY';

    let content = (startDate ? moment(startDate).format(dateFormat) : dateFormat) + ' - ' + (endDate ? moment(endDate).format(dateFormat) : dateFormat);
    const color = '#3498ff';
    return <div style={style}>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: 5, border: '1px solid #1675e0', paddingInline: '5px'}}>
            <Button style={{minWidth: '220px', color: '#1675e0'}} onClick={(event) => setAnchorEl(event.currentTarget)}>
                <DateRange fontSize='small'  color='disabled'></DateRange>
                {content}
            </Button>
            <Clear fontSize='small' color='action' onClick={() => {
                setEndDate(null)
                setStartDate(null)
                onChange([startDate, endDate])
            }}></Clear>
        </div>

        <Popover
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div style={{display: 'flex', flexDirection: 'column', maxWidth: '440px'}}>
                <div style={{width: '100%', padding: '10px'}}>
                    <Text>{content}</Text>
                </div>
                <Divider orientation="horizontal" variant="fullWidth"/>
                <div style={{display: 'flex'}}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        maxDate={endDate}

                        inline/>
                    <Divider flexItem={true} orientation="vertical" variant="fullWidth"/>
                    <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(moment(date).endOf('month').toDate())}
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        inline
                    />
                </div>
                <Divider orientation="horizontal" variant="fullWidth"/>
                <div style={{display: 'flex', padding: '5px'}}>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {ranges.map(range => {
                            return <Button color='secondary' onClick={() => {
                                setStartDate(range.value[0])
                                setEndDate(range.value[1])
                                setAnchorEl(null);
                                onChange([range.value[0], range.value[1]]);
                            }}>{range.label}</Button>
                        })}
                    </div>
                    <div style={{paddingTop: '5px'}}>
                        <Button disabled={!startDate || !endDate} variant="contained" style={{backgroundColor: color, color: 'white'}}
                                onClick={() => {
                                    setAnchorEl(null);
                                    onChange([startDate, endDate]);
                                }}>OK</Button>
                    </div>
                </div>
            </div>
        </Popover>
    </div>
}
