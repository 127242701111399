import * as dateFns from 'date-fns';
import moment from 'moment';
import React from "react";
import {ValueType, RangeType} from "../component/MonthRangePicker";

let quarterFormat = '[q]Q-Y';

let startOfCurrentQuarter = moment().quarter(moment().quarter()).startOf('quarter').toDate();
let endOfCurrentQuarter = moment().quarter(moment().quarter()).endOf('quarter').toDate();

const minusOneQuarterStart = moment(startOfCurrentQuarter).subtract(1, 'Q').toDate();
const minusOneQuarterEnd = moment(minusOneQuarterStart).endOf('quarter').toDate();

const minusTwoQuarterStart = moment(startOfCurrentQuarter).subtract(2, 'Q').toDate();
const minusTwoQuarterEnd = moment(minusTwoQuarterStart).endOf('quarter').toDate();

const minusThreeQuarterStart = moment(startOfCurrentQuarter).subtract(3, 'Q').toDate();
const minusThreeQuarterEnd = moment(minusThreeQuarterStart).endOf('quarter').toDate();

const minusFourQuarterStart = moment(startOfCurrentQuarter).subtract(4, 'Q').toDate();
const minusFourQuarterEnd = moment(minusFourQuarterStart).endOf('quarter').toDate();


export const datePickerRanges: RangeType[] = [
    {
        label: 'This year',
        value: [moment(startOfCurrentQuarter).startOf('y').toDate(),
            moment().toDate()]
    }, {
        label: 'Last year',
        value: [moment(startOfCurrentQuarter).subtract(1, 'y').startOf('y').toDate(),
            moment(endOfCurrentQuarter).subtract(1, 'y').endOf('y').toDate()]
    },
    {
        label: moment().subtract(1, 'Q').format(quarterFormat),
        value: [minusOneQuarterStart, minusOneQuarterEnd]
    }, {
        label: moment().subtract(2, 'Q').format(quarterFormat),
        value: [minusTwoQuarterStart, minusTwoQuarterEnd]
    }, {
        label: moment().subtract(3, 'Q').format(quarterFormat),
        value: [minusThreeQuarterStart, minusThreeQuarterEnd]
    }, {
        label: moment().subtract(4, 'Q').format(quarterFormat),
        value: [minusFourQuarterStart, minusFourQuarterEnd]
    }
];
