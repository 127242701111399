import {TextField, Button, Card, CardActions, CircularProgress} from "@material-ui/core";
import {Field, withTypes} from "react-final-form";
import * as React from "react";
import { useTranslate, useLogin, useNotify} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {simpleRest} from "../simpleRest";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
        width: '100%'
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

interface FormValues {
    currentPassword?: string;
    newPassword?: string;
    repeatPassword?: string;
}

const {Form} = withTypes<FormValues>();


const renderInput = ({
                         meta: {touched, error} = {touched: false, error: undefined},
                         input: {...inputProps},
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const ChangePassword = (props) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();
    let notify = useNotify();


    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        simpleRest.post('account/change-password', auth)
            .then(rs => {
                notify('Update success', 'info')
                setLoading(false);
            }).catch(rs => {
            if (rs.status == '400') {
                notify(rs.body.title, 'error')
            } else {
                throw new Error(rs)
            }

            setLoading(false);

        })
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};

        if (values.newPassword !== values.repeatPassword) {
            errors.repeatPassword = translate('post.validation.password_miss_match');
        }

        return errors;
    };

    return <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({handleSubmit}) => (
            <form style={{width: '500px', margin: 'auto'}} onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="currentPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('pos.profile.password')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="newPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('pos.profile.new_password')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                            <div className={classes.input}>
                                <Field
                                    name="repeatPassword"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('pos.profile.repeat_password')}
                                    type="password"
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.profile.submit')}
                            </Button>
                        </CardActions>
                </div>
            </form>
        )}
    />
}

export default ChangePassword;
