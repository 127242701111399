import {httpClient} from "./dataProvider";
import {stringify} from "querystring";
import {fetchUtils} from "ra-core";

export const simpleRest = {

    get: (resource, params) => {
        return httpClient(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}?${stringify(params)}`, {
            method: 'GET'
        })
    },
    getList: (resource, params) => {

        return httpClient(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}?${stringify(params)}`, {
            method: 'GET'
        })
    },
    patch(resource: string, params: {}) {
        return httpClient(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`, {
            method: 'PATCH',
            body: JSON.stringify(params)
        })
    },

    post(resource: string, params: {}) {
        return httpClient(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params)
        })
    },
    getNoAuth: (resource, params) => {
        const options = {
            method: 'GET',
            headers : new Headers({ Accept: 'application/json' })
        };

        return fetchUtils.fetchJson(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}?${stringify(params)}`, options);

    },
    postNoAuth(resource: string, params: {}) {
        const options = {
            method: 'POST',
            body: JSON.stringify(params),
            headers : new Headers({ Accept: 'application/json' })
        };
        return fetchUtils.fetchJson(process.env.REACT_APP_ADMIN_API_ENDPOINT + `/${resource}`, options);
    }
}
