import {TextField, Button, Card, CardActions, CircularProgress} from "@material-ui/core";
import {Field, withTypes} from "react-final-form";
import * as React from "react";
import {Notification, useTranslate, useLogin, useNotify, showNotification, useRedirect} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {useState} from "react";
import {simpleRest} from "../simpleRest";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

interface FormValues {
    mail?: string;
}

const {Form} = withTypes<FormValues>();


const renderInput = ({
                         meta: {touched, error} = {touched: false, error: undefined},
                         input: {...inputProps},
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const EnterEmail = (props) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const translate = useTranslate();
    const redirect = useRedirect();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        simpleRest.postNoAuth('account/reset-password/init', auth)
            .then(rs => {
                props.showNotification('Reset key sent successfully')
                setLoading(false);
                redirect('enter-reset-key')
            }).catch(rs => {
            if (rs.status == '400') {
                props.showNotification(rs.body.title)
            } else {
                throw new Error(rs)
            }

            setLoading(false);

        })
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.mail) {
            errors.mail = translate('pos.validation.email_invalid');
        } else if(!values.mail.match( /^([a-zA-Z0-9_+\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
            errors.mail = translate('pos.validation.email_invalid');
        }

        return errors;
    };

    return <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({handleSubmit}) => (
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                    <Card className={classes.card}>
                        <div className={classes.hint}>
                            {translate('pos.auth.enter_reset_email_title')}
                        </div>
                        <div className={classes.form}>
                            <div className={classes.input}>
                                <Field
                                    name="mail"
                                    // @ts-ignore
                                    component={renderInput}
                                    label={translate('pos.auth.email')}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <CardActions className={classes.actions}>
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('pos.auth.submit_reset_email')}
                            </Button>
                        </CardActions>
                    </Card>
                    <Notification/>
                </div>
            </form>
        )}
    />
}

export default connect(undefined, {showNotification})(EnterEmail);
