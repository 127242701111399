import ChangePasswordPage from './ChangePassword';
import UserDatails from './UserDatails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Card, Theme} from "@material-ui/core";
import ChangePassword from "./ChangePassword";


const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            style={{width: '80%', alignItems: 'center'}}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export const Profile = () => {
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Card style={{display: 'flex'}}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >

                <Tab label="Profile" {...a11yProps(0)} />
                <Tab label="Change Password" {...a11yProps(0)} />

            </Tabs>
            <TabPanel value={value} index={0}>
                <UserDatails></UserDatails>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ChangePassword></ChangePassword>
            </TabPanel>
        </Card>
    )
}
