import {Datagrid, Link, useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {Receipt} from "@material-ui/icons";
import {CardHeader, Card, List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
    highlight: {
        fontWeight: 'bold',
        color: '#5698fc'
    }
}));

export const ContractRevenueList = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const {contracts} = props;
    return <Card className={classes.root}>
        <CardHeader title={translate('pos.dashboard.contract_revenue_list')}/>
        <List dense={true}>
            {contracts.map(record => {

                return <ListItem
                    key={record.contractNumber}
                    button
                    component={Link}
                    to={`/revenues/tracks?filter={"contractNumber": ${record.contractNumber}}&order=DESC&page=0&perPage=25&sort=trackTitle`}
                >
                    <Receipt fontSize="large"></Receipt>
                    <ListItemText
                        primary={record.contractNumber}
                        secondary={record.signedDate}
                    />
                    <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                {record.revenue.toLocaleString([], {maximumFractionDigits: 0, style: 'currency',currency: 'VND'})}
                            </span>
                    </ListItemSecondaryAction>
                </ListItem>
            })}
        </List>
    </Card>
}
