import {
    List,
} from 'ra-ui-materialui';
import {
    Show,
    DateField,
    TextField,
    SimpleShowLayout, NumberField,
} from 'react-admin';


export const ContractShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField label="Track Id" source="trackId"/>
            <TextField source="trackIdType"/>
            <TextField label="Contract Number" source="contractId"/>
            <TextField source="upc" />
            <TextField source="idType" />
            <NumberField source="discNumber" />
            <NumberField source="trackNumber" />
            <TextField source="trackTitle" />
            <TextField label="Track Artist Name" source="trackArtistName1"/>
        </SimpleShowLayout>
    </Show>
);
