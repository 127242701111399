import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import * as React from 'react';
import ReactDOM from 'react-dom';
import {simpleDataProvider} from "./dataProvider";
import App from './App';
import './index.css';

const prepareDataProvider = async () => {
    return { dataProvider: simpleDataProvider };
};

prepareDataProvider().then(({ dataProvider }) => {
    ReactDOM.render(
        <App dataProvider={dataProvider} />,
        document.getElementById('root')
    );
});
