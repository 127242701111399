import {Datagrid, Link, useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {Album, Visibility} from "@material-ui/icons";
import {CardHeader, Card, List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';
import './Trending.css'
import {Text} from "recharts";

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    views: {
        marginRight: '1em',
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
    highlight: {
        fontWeight: 'bold',
        color: '#5698fc'
    },
}));

export const Trending = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const {tracks} = props;
    return <Card className={classes.root}>
        <CardHeader title={translate('pos.dashboard.trending_tracks')}/>
        <List style={{height: '100%', overflow: 'auto'}} dense={true}>
            {tracks.map(record => {

                return <ListItem
                    key={record.id}
                    button
                    component={Link}
                    to={`/revenues/tracks?displayedFilters={"trackId":true}&filter={"trackId": "${record.id}"}&order=DESC&page=0&perPage=25`}
                >
                    <Album fontSize="large"></Album>
                    <ListItemText
                        primary={record.trackTitle}
                        secondary={record.trackArtistName}
                    />
                    <ListItemSecondaryAction>
                        <Text className={classes.views}>
                                {record.views.toLocaleString()}
                            </Text>
                    </ListItemSecondaryAction>
                </ListItem>
            })}
        </List>
    </Card>

}
