import * as React from 'react';
import {Admin, Resource, DataProvider, ListGuesser, useLogout, ShowGuesser} from 'react-admin';

import authProvider from './authProvider';
import themeReducer from './themeReducer';
import {Login, Layout, Menu} from './layout';
import customRoutes from './routes';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import vietnameseMessages from "./i18n/vi";
import {Dashboard} from "./dashboard";
import {useEffect} from "react";
import platformDataStore from "./store/platformDataStore";
import {simpleRest} from "./simpleRest";
import { InitialResource } from './InitialResource';
import {RevenueList} from "./revenue/RevenueList";
import {ContractList} from "./contracts/ContractList";
import {ContractShow} from "./contracts/ContractShow";
import {PaymentList} from "./payments/PaymentList";

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }

    // Always fallback on english
    return vietnameseMessages;
}, 'vi');

interface AppProps {
    dataProvider: DataProvider;
}

const App = ({dataProvider}: AppProps) => {

    return (
        <> <Admin
            title=""
            dataProvider={dataProvider}
            customReducers={{theme: themeReducer}}
            customRoutes={customRoutes}
            authProvider={authProvider}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            dashboard={Dashboard}
            menu={Menu}
            disableTelemetry
        >
            <Resource options={{label: "Revenues"}} name="revenues/tracks" list={RevenueList}/>
            <Resource name="platforms" ></Resource>
            <Resource options={{label: "Albums & Singles"}} name="contracts" list={ContractList} show={ContractShow}></Resource>
            <Resource name="payments" list={PaymentList}/>
            <InitialResource></InitialResource>
        </Admin>
        </>
    );
};

export default App;
