import {
    List,
} from 'ra-ui-materialui';
import {
    Datagrid,
    DateField,
    Filter,
    NumberField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    highlight: {
        fontWeight: 'bold',
        color: '#5698fc'
    }
});

export const PaymentList = props => {
    const classes = useStyles();


    return (
        <List bulkActionButtons={false} {...props}>
            <Datagrid>
                <TextField source="contractNumber"/>
                <TextField source="bankNumber"/>
                <TextField source="bankName"/>
                <TextField source="bankBrand"/>
                <TextField source="bankReceiver"/>
                <NumberField className={classes.highlight} source="payment"/>
                <TextField source="currency"/>
                <TextField source="transferText"/>
                <DateField source="paymentDate"/>
            </Datagrid>
        </List>
    );
}
