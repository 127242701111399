import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {makeStyles} from "@material-ui/core/styles";
import {Typography, Grid, Switch, Divider} from "@material-ui/core";
import {useTranslate, useGetIdentity, useDataProvider, showNotification, Title} from "react-admin";
import {useEffect, useState} from "react";
import {simpleRest} from "../simpleRest";
import * as React from "react";
import {connect} from 'react-redux'

const useStyles = makeStyles({
    root: {width: 600, margin: 'auto'},
    spacer: {height: 20},
    invoices: {margin: '10px 0'},
    label: {display: 'inline-block'},
    cardContent: {display: 'flex', justifyContent: 'space-between'},
    contentValue: {justifyContent: 'flex-end', display: 'flex'}

});

const UserDetailsView = (props) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { loaded, identity } = useGetIdentity();
    const [enableEmailNotification, setEnableEmailNotification] = useState(true);
    const dataProvider = useDataProvider()
    const [details, setDetails] = useState<any>({});
    useEffect(() => {
        if(loaded) {
            simpleRest.get('account', {
            }).then(rs => {
                setDetails(rs.json.details);
                setEnableEmailNotification(rs.json.enableEmailNotification);

            })
        }
    },[loaded]);

    const {contractSigner, companyName, email, address, bankName, bankBrand, bankNumber, bankReceiver} = details;

    const handleNotificationEnable = (event, value) => {

        simpleRest.patch('account', {
            enableEmailNotification: value
        }).then(rs => {
            props.showNotification('Update success')
            setEnableEmailNotification(value);
        })
    };

    return (
        <div className={classes.root}>
            <Title title={translate('pos.profile.title')} />
            <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1"  className={classes.label}>
                    {translate('pos.profile.username')}
                </Typography>
                <Typography variant="subtitle2"  align="right" className={classes.label}>
                    {contractSigner}
                </Typography>
            </CardContent>
            <Divider variant="fullWidth"/>
            <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1" className={classes.label}>
                    {translate('pos.profile.company_name')}
                </Typography>
                <Typography variant="subtitle2" align="right" className={classes.label}>
                    {companyName}
                </Typography>
            </CardContent>
            <Divider variant="fullWidth"/>
            <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1" gutterBottom className={classes.label}>
                    {translate('pos.profile.email')}
                </Typography>
                <Typography variant="subtitle2" gutterBottom align="right" className={classes.label}>
                    {email}
                </Typography>
            </CardContent>
            <Divider variant="fullWidth"/>
            <CardContent>
                <Typography variant="subtitle1" gutterBottom className={classes.label}>
                    {translate('pos.profile.address')}
                </Typography>
                <Typography variant="h6" gutterBottom align="right" className={classes.label}>
                    {address}
                </Typography>
            </CardContent>
            <Divider variant="fullWidth" />
            <CardContent className={classes.cardContent}>
                <Typography variant="subtitle1" gutterBottom className={classes.label}>
                    {translate('pos.profile.enable_email_notification')}
                </Typography>
                <Switch
                    checked={enableEmailNotification}
                    onChange={handleNotificationEnable}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </CardContent>
            <Divider variant="fullWidth"/>
            <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                    {translate('pos.profile.bank.title')}
                </Typography>
            </CardContent>
            <CardContent style={{paddingLeft: '50px'}}>
                <Grid justify="space-between" container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom className={classes.label}>
                            {translate('pos.profile.bank.bank_name')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.contentValue}>
                        <Typography variant="subtitle2" gutterBottom align="right" className={classes.label}>
                            {bankName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid justify="space-between" container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom className={classes.label}>
                            {translate('pos.profile.bank.bank_brand')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.contentValue}>
                        <Typography variant="subtitle2" gutterBottom align="right" className={classes.label}>
                            {bankBrand}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid justify="space-between" container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom className={classes.label}>
                            {translate('pos.profile.bank.bank_number')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.contentValue}>
                        <Typography variant="subtitle2" gutterBottom align="right" className={classes.label}>
                            {bankNumber}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid justify="space-between" container spacing={2} >
                    <Grid item xs={6}>
                        <Typography variant="subtitle2" gutterBottom className={classes.label}>
                            {translate('pos.profile.bank.bank_receiver')}
                        </Typography>
                    </Grid>
                    <Grid className={classes.contentValue}>
                        <Typography variant="subtitle2" gutterBottom align="right" className={classes.label}>
                            {bankReceiver}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>

        </div>
    );
};

export default connect(undefined, { showNotification })(UserDetailsView);
