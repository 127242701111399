import {Doughnut} from "react-chartjs-2";
import React from "react";
import {getPlatformColorById, getPlatformNameById} from "../util";
import platformDataStore from "../store/platformDataStore";
import {Chart, registerables} from 'chart.js';
import doughnutlabel from 'chartjs-plugin-doughnutlabel';

interface Props {
    data: any,
    title: string
}

export const DoughnutChart = ({data, title}: Props) => {

    let platforms = platformDataStore.getState();
    const placeHolderData = {
        labels: platforms.map(p => p.name),
        datasets: [{
            label: title,
            data: platforms.map(p => 0),
            backgroundColor: platforms.map(p => p.color),
            hoverOffset: 4,
        }],
        text: 'No data'
    };


    return <div style={styles.container}><Doughnut options={{
        plugins: {
            legend: {
                display: true,
                position: 'right'
            },
            title: {
                display: true,
                text: title,
                color: 'black',
                position: 'bottom'
            }
        }
    }} data={data.labels.length === 0 ? placeHolderData : data} type='doughnut'/></div>
}

const styles = {
    container: {
        width: '400px',
        height: '400px',
        margin: '20px'
    },
}
