import React, {FC, useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";
import TotalRevenue from './TotalRevenue'
import Views from "./Views";
import moment from 'moment';
import {format, parse} from "date-fns";
import {useNotify, useDataProvider, useLogout, Loading} from 'react-admin';
import {datePickerRanges} from "./DatePickerRanges";
import {simpleRest} from "../simpleRest";
import {getPlatformColorById, getPlatformNameById} from "../util";
import {DoughnutChart} from "../component/DoughnutChart";
import {Trending} from "./Trending";
import TotalPayment from "./TotalPayment";
import Revenue from "./Revenue";
import {ContractRevenueList} from "./ContractRevenueList";
import {MonthRangePicker} from "../component/MonthRangePicker";

const styles = {
    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
    filters: {
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '10px 0'
    },
    revenueChart: {
        display: 'flex',
        margin: '20px'
    },
};

const Spacer = () => <span style={{width: '1em'}}/>;
const VerticalSpacer = () => <span style={{height: '1em', display: 'block'}}/>;

type Statistic = {
    revenue: number;
    views: number;
    platformStatistics: { platformId: string, totalViews: number, totalRevenue: number }[];

};
const Dashboard: FC = (props) => {
    const defaultDateRange = {
        startDate: moment(new Date()).add(-1, 'y').startOf('month').toDate(),
        endDate: moment(new Date()).add(-1, 'm').endOf('month').toDate(),
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [tracks, setTracks] = useState([])
    const [contracts, setContracts] = useState([])
    const [dateRange, setDateRange] = React.useState<any>(defaultDateRange);
    const [statistics, setStatistics] = useState<Statistic>({revenue: 0, views: 0, platformStatistics: []});

    const [totalRevenue, setTotalRevenue] = useState(0);
    const [totalPaidAmount, setTotalPaidAmount] = useState(0);

    const [monthlyRevenues, setMonthlyRevenues] = useState([]);
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const logout = useLogout();

    function reloadInsight() {
        let params: any = '';

        if (!!dateRange.startDate) {
            params = {
                start: format(dateRange.startDate, "yyyy-MM-dd"),
                end: format(dateRange.endDate, "yyyy-MM-dd")
            };
        }
        return simpleRest.get('insights', params)
            // .then((rs) => {
            //     setStatistics(rs.json);})
            .catch(error => {
                if (error.status !== 401) {
                    // notify(`Error: ${error.message}`, 'error');
                    logout();
                }
            })
    }


    function reloadTrending() {
        let params: any = '';

        if (!!dateRange.startDate) {
            params = {
                start: format(dateRange.startDate, "yyyy-MM-dd"),
                end: format(dateRange.endDate, "yyyy-MM-dd")
            };
            params.size = 10;
            params.number = 0;

        }
        return simpleRest.getList('contracts/trending', params)
            // .then((rs) => {
            //     setTracks(rs.json.content);
            // })
            .catch(error => {
                if (error.status !== 401) {
                    // notify(`Error: ${error.message}`, 'error');
                    logout();
                }
            })
    }

    function reloadStatistics() {
        let params: any = '';

        if (!!dateRange.startDate) {
            params = {
                start: format(dateRange.startDate, "yyyy-MM-dd"),
                end: format(dateRange.endDate, "yyyy-MM-dd")
            };
        }
        return simpleRest.get('revenues/statistics', params)
            // .then((rs) => {
            //     setMonthlyRevenues(rs.json);
            // })
            .catch(error => {
                // notify(`Error: ${error.message}`, 'error');
                logout();

            })
    }


    function reloadTotalRevenue() {
        let params: any = '';

        if (!!dateRange.startDate) {
            params = {
                start: format(dateRange.startDate, "yyyy-MM-dd"),
                end: format(dateRange.endDate, "yyyy-MM-dd")
            };
            params.size = 10;
            params.number = 0;

        }
        return simpleRest.getList('revenues/contracts', params)
            // .then((rs) => {
            //     setContracts(rs.json);
            // })
            .catch(error => {
                if (error.status !== 401) {
                    // notify(`Error: ${error.message}`, 'error');
                    logout();
                }
            })
    }

    async function reloadAll() {
        await Promise.all([reloadInsight(), reloadTrending(), reloadTotalRevenue(), reloadStatistics()])
            .then((r: any[]) => {
                setStatistics(r[0].json);
                setTracks(r[1].json.content);
                setContracts(r[2].json);
                setMonthlyRevenues(r[3].json);
                setLoading(false);

                console.log(r)
            })
    }

    useEffect(() => {
        setLoading(true)
        console.log('use effet')
        reloadAll();
    }, [dateRange]);

    let platformStatistics = statistics.platformStatistics;
    const revenueChartData = {
        labels: platformStatistics.map(p => getPlatformNameById(p.platformId)),
        datasets: [{
            label: 'Total Revenue',
            data: platformStatistics.map(p => p.totalRevenue),
            backgroundColor: platformStatistics.map(p => getPlatformColorById(p.platformId)),
            hoverOffset: 4,
        }]
    }


    useEffect(() => {
        simpleRest.get('payments/total', {}).then((rs) => {
            setTotalPaidAmount(rs.json);
        }).catch(error => {
            if (error.status !== 401) {
                // notify(`Error: ${error.message}`, 'error');
                logout();
            }
        })

        simpleRest.get('revenues/total', {}).then((rs) => {
            setTotalRevenue(rs.json);
        }).catch(error => {
            if (error.status !== 401) {
                // notify(`Error: ${error.message}`, 'error');
                logout();
            }
        })
    }, [])


    const viewsChartData = {

        labels: platformStatistics.map(p => getPlatformNameById(p.platformId)),
        datasets: [{
            label: 'Total Views',
            data: platformStatistics.map(p => p.totalViews),
            backgroundColor: platformStatistics.map(p => getPlatformColorById(p.platformId)),
            hoverOffset: 4
        }]
    }

    const monthlyRevenueChartData = {
        labels: monthlyRevenues.map(r => format(parse(r.date, 'yyyyMMdd000000', new Date()), 'MMM yyyy')),
        datasets: [
            {
                label: 'Revenue Chart',
                data: monthlyRevenues.map(r => r.value),
                fill: false,
                backgroundColor: '#2bb9fa',
                borderColor: '#2bb9fa',
            },
        ],
    };

    const lineOptions = {
        scales: {
            x:
                {
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 12,
                    }
                },
            y:
                {
                    // stacked: true,
                    gridLines: {
                        display: false
                    },
                    ticks: {
                        beginAtZero: true,
                        // Return an empty string to draw the tick line but hide the tick label
                        // Return `null` or `undefined` to hide the tick line entirely
                    }
                }
        },
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        }
    };

    console.log("Calll ...")
    let body = <>
        <div style={styles.flex}>
            <Revenue value={statistics.revenue}/>
            <Spacer/>
            <Views value={statistics.views}/>
            <Spacer/>
            <TotalRevenue value={totalRevenue}/>
            <Spacer/>
            <TotalPayment value={totalRevenue - totalPaidAmount}/>
        </div>
        <VerticalSpacer/>
        <div style={styles.revenueChart}>

            <DoughnutChart title='Revenue' data={revenueChartData}/>

            <Spacer/>
            <DoughnutChart title='Streams' data={viewsChartData}/>
        </div>
        <VerticalSpacer/>
        <div>
            <Line title="Revenue Chart" data={monthlyRevenueChartData} type='line' options={lineOptions}></Line>

        </div>
        <VerticalSpacer/>
        <VerticalSpacer/>
        <VerticalSpacer/>
        <div style={styles.flex}>
            <div style={styles.leftCol}>
                <Trending tracks={tracks}></Trending>
            </div>
            <div style={styles.rightCol}>
                <ContractRevenueList contracts={contracts}></ContractRevenueList>
            </div>
        </div>
    </>;
    return (
        <div>
            <div style={styles.filters}>
                <MonthRangePicker
                    value={[dateRange.startDate, dateRange.endDate]}
                    ranges={datePickerRanges}
                    onChange={(range) => {
                        setDateRange({startDate: range[0], endDate: range[1]})
                    }}
                    format={"MM/YYYY"}
                ></MonthRangePicker>

            </div>
            {loading? <Loading /> : body}

        </div>
    );
};

export default Dashboard;
