import platformDataStore from "./store/platformDataStore";

export function getPlatformColorById(id) {
    return platformDataStore.getState().find(p => p.id === id).color;
}


export function getPlatformNameById(id) {
    return platformDataStore.getState().find(p => p.id === id).name;
}



