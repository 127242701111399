import {
    List,
} from 'ra-ui-materialui';
import {
    Datagrid,
    DateField,
    Filter,
    NumberField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import {DateRangePickerInput} from "../component/DateRangePickerInput";
import {makeStyles} from "@material-ui/core/styles";
import * as queryString from "query-string";
import {useEffect} from "react";


const RevenueFilter = (props: any) => {
    let displayedFilters: any = {...props.displayedFilters};

    const parsed = queryString.parse(props.location.search);
    console.log(parsed)

    // if (parsed['contractNumber.equals']) {
    //     displayedFilters = {
    //         'contractNumber.equals': true,
    //         ...displayedFilters
    //     }
    // }
    // if (parsed['trackId.equals']) {
    //     displayedFilters = {
    //         'trackId.equals': true,
    //         ...displayedFilters
    //     }
    // }
    return (
        <Filter {...props} displayedFilters={displayedFilters}>
            <ReferenceInput label="Platform"
                            source="platformId"
                            reference="platforms"

                            allowEmpty
            >
                <SelectInput optionText="name">
                </SelectInput>
            </ReferenceInput>
            <TextInput label="Artist Name" source="artistName.contains"></TextInput>
            <TextInput label="Track ID" source="trackId"></TextInput>
            <TextInput label="Contract Number" source="contractNumber"></TextInput>
            <DateRangePickerInput label={"Report Date"} source="datesFilter"></DateRangePickerInput>
        </Filter>
    );
}
const useStyles = makeStyles({
    highlightNumber: {
        fontWeight: 'bold'
    },
    highlight: {
        fontWeight: 'bold',
        color: '#5698fc'
    }
});
export const RevenueList = props => {

    const classes = useStyles();
    return (
        <List style={{width: '1300px'}} filters={<RevenueFilter {...props}/>} bulkActionButtons={false} {...props}>
            <Datagrid rowClick="show">
                <TextField className={classes.highlight} style={{width: '250px'}} source="trackTitle"/>
                <TextField label="Track ID" source="trackId"/>
                <NumberField className={classes.highlightNumber} label="Revenue (VND)" options={{
                    style: 'currency',
                    currency: 'VND',
                }} source="totalRevenue"/>
                <NumberField className={classes.highlightNumber} source="totalViews"/>
                <TextField label="Platform" source="platformId"/>
                <DateField source="saleDate"/>
                <TextField source="contractId"/>
                <TextField label="UPC" source="upc"/>
                <TextField  label="Artist Name" fullWidth={true} source="trackArtistName"/>
            </Datagrid>
        </List>
    );
}
