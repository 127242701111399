import {
    List,
} from 'ra-ui-materialui';
import {
    Datagrid,
    DateField,
    Filter,
    NumberField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
} from 'react-admin';
import './ContractList.css'
import {makeStyles} from "@material-ui/core/styles";
const useStyles = makeStyles({
    highlight: {
        fontWeight: 'bold',
        color: '#5698fc'
    }
});

export const ContractList = props => {
    const classes = useStyles();

    return (
        <List bulkActionButtons={false} {...props}>
            <Datagrid rowClick="show">
                <TextField className={classes.highlight} source="trackTitle"/>
                <TextField label="Artist Name" source="trackArtistName1"/>
                <TextField label="Track ID" source="trackId"/>
                <TextField label="Contract Number" source="contractId"/>
                <TextField label="UPC" source="upc"/>
            </Datagrid>
        </List>
    );
}
